<template>
  <creator-hero></creator-hero>
  <creator-why id="creator-why"></creator-why>
  <creator-workflow id="creator-workflow"></creator-workflow>
  <creator-flexibility id="creator-flexibility"></creator-flexibility>
  <creator-testimonials id="creator-testimonials"></creator-testimonials>
  <creator-contact id="creator-contact"></creator-contact>
</template>

<script>
import CreatorHero from "../components/creator/CreatorHero.vue";
import CreatorWhy from "../components/creator/CreatorWhy.vue";
import CreatorWorkflow from "../components/creator/CreatorWorkflow.vue";
import CreatorTestimonials from "../components/creator/CreatorTestimonials.vue";
import CreatorContact from "../components/creator/CreatorContact.vue";
import CreatorFlexibility from "../components/creator/CreatorFlexibility.vue";

export default {
  components: {
    "creator-hero": CreatorHero,
    "creator-why": CreatorWhy,
    "creator-workflow": CreatorWorkflow,
    "creator-testimonials": CreatorTestimonials,
    "creator-contact": CreatorContact,
    "creator-flexibility": CreatorFlexibility,
  },
};
</script>
