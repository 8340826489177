<template>
  <section>
    <div class="container section-flexibility hidden">
      <div class="section-header">
        <span class="subheading">Flexibility</span>
        <h2 class="heading-secondary">What will you build?</h2>
        <p class="heading-description">
          INVRS is unlocking new ways for finance creators to create, share, and
          monetize.
        </p>
      </div>

      <div class="flexibility-grid hidden">
        <div
          class="flexibility-option flexibility-option-create flexibility-header create"
        >
          <h3 class="heading-tertiary">Create</h3>
        </div>
        <div class="flexibility-option-create flexibility-option research">
          <img src="../../assets/research.png" class="flexibility-img" />
          <p class="flexibility-option-name">Research</p>
        </div>
        <div class="flexibility-option-create flexibility-option analysis">
          <img src="../../assets/analysis.png" class="flexibility-img" />
          <p class="flexibility-option-name">Analysis</p>
        </div>
        <div class="flexibility-option-create flexibility-option tools">
          <img src="../../assets/tools.png" class="flexibility-img" />
          <p class="flexibility-option-name">Tools</p>
        </div>
        <div class="flexibility-option-create flexibility-option portfolio">
          <img src="../../assets/portfolio2.png" class="flexibility-img" />
          <p class="flexibility-option-name">Portfolio</p>
        </div>
        <div
          class="flexibility-option flexibility-option-earn flexibility-header"
        >
          <h3 class="heading-tertiary">Earn</h3>
        </div>
        <div class="flexibility-option-earn flexibility-option">
          <img src="../../assets/subscriptions.png" class="flexibility-img" />
          <p class="flexibility-option-name">Subscriptions</p>
        </div>
        <div class="flexibility-option-earn flexibility-option">
          <img src="../../assets/affiliate.png" class="flexibility-img" />
          <p class="flexibility-option-name">Affiliate Program</p>
        </div>
        <div class="flexibility-option-earn flexibility-option">
          <img src="../../assets/piechart.png" class="flexibility-img" />
          <p class="flexibility-option-name">Ad Share</p>
        </div>
        <div class="flexibility-option-earn flexibility-option">
          <img src="../../assets/onetime.png" class="flexibility-img" />
          <p class="flexibility-option-name">One-time Sales</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      section: "",
      options: [],
      obsOptionsSection: {
        root: null,
        threshold: 0.2,
      },
      obsOptionsOptions: {
        root: null,
        threshold: 0.6,
      },
    };
  },

  mounted() {
    this.section = document.querySelector(".section-flexibility");
    this.options = document.querySelector(".flexibility-grid");
    const sectionObserver = new IntersectionObserver(
      this.revealSection,
      this.obsOptionsSection
    );
    sectionObserver.observe(this.section);

    const optionsObserver = new IntersectionObserver(
      this.revealOptions,
      this.obsOptionsOptions
    );
    optionsObserver.observe(this.section);
  },

  methods: {
    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },
    revealOptions(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.options.classList.remove("hidden");
        }
      });
    },
  },
};
</script>

<style scoped>
section {
  padding: 9.6rem 0;
}

.section-flexibility {
  transition: all 0.3s ease-in;
}

.section-header {
  display: flex;
  flex-direction: column;
}
.heading-description {
  line-height: 3.5rem;
  width: 60%;
  margin-bottom: 4.8rem;
}

.flexibility-grid {
  display: grid;
  grid-template-columns: 1fr repeat(4, 2fr);
  align-items: center;
  grid-gap: 2px;
  background-color: #f2f4fa;
  transition: all 0.3s ease-in;
}

.flexibility-header {
  align-self: stretch;
  justify-self: stretch;
}

.flexibility-option {
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}

.flexibility-header {
  align-self: stretch;
  justify-self: stretch;
}

.flexibility-option-create {
  grid-row: 1;
}

.flexibility-img {
  height: 30%;
  width: 30%;
  margin-bottom: 1.2rem;
}

.flexibility-option-name {
  font-size: 1.8rem;
}

.hidden {
  transform: translateY(4.8rem);
  opacity: 0;
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .heading-description {
    width: 100%;
  }

  .flexibility-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr repeat(4, 2fr);
    width: 70%;
    margin: 0 auto;
  }

  .flexibility-option {
    height: 100%;
  }

  .flexibility-img {
    height: 7.2rem;
    width: 7.2rem;
  }

  .create {
    grid-row: 1;
    grid-column: 1;
  }
  .research {
    grid-row: 2;
    grid-column: 1;
  }
  .analysis {
    grid-row: 3;
    grid-column: 1;
  }
  .tools {
    grid-row: 4;
    grid-column: 1;
  }
  .portfolio {
    grid-row: 5;
    grid-column: 1;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .flexibility-grid {
    width: 100%;
  }

  .flexibility-img {
    height: 6.4rem;
    width: 6.4rem;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .flexibility-img {
    height: 5.6rem;
    width: 5.6rem;
  }
}
</style>
