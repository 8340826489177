<template>
  <section class="section-hero">
    <div class="hero container">
      <div class="hero-content hidden-content">
        <h1 class="heading-primary">
          The complete workflow for finance creators.
        </h1>
        <p class="hero-description">
          Reimagining the creative process - giving you new ways to create,
          grow, and monetize on a global scale.
        </p>
        <button @click="goToSignup">
          <span>Start creating</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 74 74"
          >
            <path
              fill="white"
              d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z"
            ></path>
          </svg>
        </button>
      </div>

      <div class="hero-visuals">
        <div class="hero-card hero-card-1 hidden-card">
          <img src="../../assets/connect.png" class="card-img" />
          <div class="card-content">
            <h3 class="heading-tertiary">Eli Rodney</h3>
            <p class="card-text">
              <strong>Followed</strong> you along with 45 others.
            </p>
          </div>
        </div>
        <div class="hero-card hero-card-2 hidden-card">
          <img src="../../assets/comment.png" class="card-img" />
          <div class="card-content">
            <h3 class="heading-tertiary">Eli Rodney</h3>
            <p class="card-text">
              <strong>Commented</strong> on your post: This research is amazing.
              Where can I get more?
            </p>
          </div>
        </div>
        <div class="hero-card hero-card-3 hidden-card">
          <img src="../../assets/monetize.png" class="card-img" />
          <div class="card-content">
            <h3 class="heading-tertiary">Eli Rodney</h3>
            <p class="card-text">
              <strong>Subscribed</strong> to your premium research for
              $15/month!
            </p>
          </div>
        </div>

        <img
          class="hero-img hidden-img"
          alt="Screenshot of the INVRS markets page, including stock market data"
          src="../../assets/earth.png"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { SIGNUP_URL } from "../../config.js";

export default {
  data() {
    return {
      heroSection: "",
      heroContent: "",
      heroCards: [],
      obsOptionsHero: {
        root: null,
        threshold: 0.1,
      },
    };
  },
  mounted() {
    this.heroSection = document.querySelector(".section-hero");
    this.heroContent = document.querySelector(".hero-content");
    this.heroCards = document.querySelectorAll(".hero-card");
    const heroObserver = new IntersectionObserver(
      this.revealHero,
      this.obsOptionsHero
    );
    heroObserver.observe(this.heroSection);
  },
  methods: {
    revealHeroContent() {
      this.heroContent.classList.remove("hidden-content");
    },
    revealHeroCards() {
      setTimeout(() => {
        this.heroCards.forEach((v, i) => {
          setTimeout(() => v.classList.remove("hidden-card"), i * 250);
        });
      }, 300);
    },
    revealHero() {
      this.revealHeroContent();
      this.revealHeroCards();
    },
    goToSignup() {
      window.location.href = SIGNUP_URL;
    },
  },
};
</script>

<style scoped>
.section-hero {
  position: relative;
  padding: 19rem 0 9.6rem 0;
  background: linear-gradient(180deg, #f2f4fa 32.54%, #f2f4fa00 100%);
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hidden-content {
  transform: translateX(-9.6rem);
  opacity: 0;
}

.hero-content {
  transition: all 0.3s ease-in;
  margin-right: 4.8rem;
  flex: 1 1 0px;
}

.hero-description {
  line-height: 1.6;
  margin-bottom: 4.8rem;
  max-width: 100rem;
  color: #333;
}

.hero-visuals {
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
}

.hero-card {
  display: flex;
  background-color: #fff;
  padding: 1.2rem;
  border-radius: 0.8rem;
  margin-bottom: 4.8rem;
  z-index: 1;
  box-shadow: 0 0 2.4rem hsl(231deg 22% 25% / 20%);
  transition: all 0.3s ease-in-out;
}

.hidden-card {
  opacity: 0;
  transform: scale(0.5) translate(-2.4rem, 4.8rem);
}

.hero-card-1,
.hero-card-3 {
  margin-left: 2.4rem;
}

.hero-card-2 {
  margin-right: 2.4rem;
}

.card-img {
  height: 4.8rem;
  margin-right: 1.2rem;
}

.card-content {
  display: flex;
  flex-direction: column;
}

.heading-tertiary {
  font-size: 2rem;
  font-weight: 600;
}

.card-text {
  font-size: 1.8rem;
  line-height: 1.2;
}

strong {
  font-weight: 500;
  color: #333;
}

.hero-card-3 strong {
  color: #3c9;
}

.hero-img {
  position: absolute;
  right: 0;
  transform: translate(55%, -10%);
  transition: all 0.3s ease-in;
}

.hidden-contact {
  transform: translateX(-4.8rem);
  opacity: 0;
}

button {
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 0.8rem;
  background-image: linear-gradient(
    to right,
    rgb(105, 59, 245, 1),
    rgb(105, 59, 245, 0.85),
    rgb(105, 59, 245, 0.7)
  );
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 2rem;
}

button:hover {
  box-shadow: 0 0.4rem 3.2rem rgb(105, 59, 245, 0.6);
  transform: scale(1.05);
}

button > svg {
  width: 3.6rem;
  margin-left: 1rem;
  transition: transform 0.3s ease-in-out;
  font-weight: 700;
}

button:hover svg {
  transform: translateX(5px);
}

button:active {
  transform: scale(0.95);
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
  .hero {
    max-width: 120rem;
  }

  .heading-primary {
    font-size: 6rem;
  }

  .hero-img {
    width: 40rem;
  }
}

/* **************************** */
/* BELOW 976px (landscape tablets) */
/* **************************** */
@media (max-width: 61em) {
  .heading-primary {
    font-size: 5.6rem;
  }

  .hero-img {
    width: 36rem;
  }
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .hero-card {
    display: none;
  }

  .hero-visuals {
    flex: 0 0 0px;
  }

  .hero-img {
    transform: translate(55%, 10%);
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .heading-primary {
    font-size: 5rem;
  }

  .hero-description {
    width: 90%;
  }

  .hero-img {
    width: 30rem;
    transform: translate(55%, 20%);
  }
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  .heading-primary {
    font-size: 4.4rem;
  }

  button {
    padding: 8px 16px;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .hero-img {
    transform: translate(55%, 30%);
  }
}
</style>
