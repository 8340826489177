<template>
  <section>
    <div class="container">
      <div class="section-data hidden">
        <div class="section-header">
          <span class="subheading">Workflow</span>
          <h2 class="heading-secondary">Streamlining the creative process</h2>
          <p class="heading-description">
            From analyzing opportunities to creating, distributing, and
            monetizing content; INVRS has you covered.
          </p>
        </div>
        <div class="btn-container">
          <div class="slider">
            <div class="slide slide-1">
              <img class="slide-img" src="../../assets/conduct-analysis.png" />
              <div class="slide-description">
                <img class="slide-icon" src="../../assets/data-icon.png" />
                <div class="slide-text-container">
                  <h3 class="slide-heading">Conduct analysis</h3>
                  <p class="slide-text">
                    All the information you need for your content.
                  </p>
                </div>
              </div>
            </div>
            <div class="slide slide-2">
              <img class="slide-img" src="../../assets/note-creation.png" />
              <div class="slide-description">
                <img class="slide-icon" src="../../assets/create-icon.png" />
                <div class="slide-text-container">
                  <h3 class="slide-heading">Create content</h3>
                  <p class="slide-text">
                    Intuitive tools to help speed up content creation.
                  </p>
                </div>
              </div>
            </div>
            <div class="slide slide-3">
              <img class="slide-img" src="../../assets/add-tags.png" />
              <div class="slide-description">
                <img class="slide-icon" src="../../assets/tags-icon.png" />
                <div class="slide-text-container">
                  <h3 class="slide-heading">Add tags</h3>
                  <p class="slide-text">
                    Make sure your content goes to who is looking for it.
                  </p>
                </div>
              </div>
            </div>
            <div class="slide slide-4">
              <img class="slide-img" src="../../assets/posted-note.png" />
              <div class="slide-description">
                <img class="slide-icon" src="../../assets/share-icon.png" />
                <div class="slide-text-container">
                  <h3 class="slide-heading">Share your work</h3>
                  <p class="slide-text">
                    Distrbute your content and interact with your audience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button class="slider__btn slider__btn--left" @click="prevSlide">
            &larr;
          </button>
          <button class="slider__btn slider__btn--right" @click="nextSlide">
            &rarr;
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      slides: [],
      curSlide: 0,
      obsOptionsData: {
        root: null,
        threshold: 0.2,
      },
      section: "",
    };
  },

  mounted: function () {
    this.slides = document.querySelectorAll(".slide");
    this.init();

    this.section = document.querySelector(".section-data");
    const dataSectionObserver = new IntersectionObserver(
      this.revealSection,
      this.obsOptionsData
    );
    dataSectionObserver.observe(this.section);
  },

  methods: {
    goToSlide(slide) {
      this.slides.forEach(
        (s, i) => (s.style.transform = `translateX(${(i - slide) * 100}%`)
      );
    },

    nextSlide() {
      if (this.curSlide === this.slides.length - 1) {
        this.curSlide = 0;
      } else {
        this.curSlide++;
      }

      this.goToSlide(this.curSlide);
    },

    prevSlide() {
      if (this.curSlide === 0) {
        this.curSlide = this.slides.length - 1;
      } else {
        this.curSlide--;
      }

      this.goToSlide(this.curSlide);
    },

    init() {
      this.goToSlide(this.curSlide);
    },

    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },
  },
};
</script>

<style scoped>
section {
  padding: 9.6rem 0;
  background-color: #32364e;
  transform: skewY(3deg);
  box-shadow: inset 4.8rem 4.8rem 9.6rem #2b2e42,
    inset -4.8rem -4.8rem 9.6rem #3a3e5a;
}

.container {
  transform: skewY(-3deg);
}

.section-header {
  display: flex;
  flex-direction: column;
}

.subheading {
  color: #33cc99;
}

.heading-secondary {
  color: #fff;
}

.heading-description {
  line-height: 3.5rem;
  width: 60%;
  color: #f2f4fa;
  margin-bottom: 4.8rem;
}

.slider {
  width: 100%;
  height: 70rem;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
}

.slide {
  position: absolute;
  top: 0;
  width: 100%;
  height: 70rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  transition: transform 0.5s;
}

.slide-text-container {
  display: flex;
  flex-direction: column;
}

.slide-description {
  display: flex;
  align-items: center;
  width: 95%;
}

.slide-img {
  width: 95%;
  object-fit: cover;
  margin-bottom: 3.6rem;
  align-self: center;
  border-radius: 8px;
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.14),
    0px 9px 38px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
}

.slide-icon {
  width: 5%;
  margin-right: 2.4rem;
}

.slide-heading {
  color: #fff;
  margin-bottom: 0.8rem;
  font-weight: 600;
}

.heading-tertiary {
  color: #fff;
}

.slide-text {
  color: #f2f4fa;
  font-size: 1.8rem;
}

.btn-container {
  position: relative;
}

.slider__btn {
  position: absolute;
  top: 40%;
  z-index: 10;

  border: none;
  background: rgb(242, 244, 250, 0.9);
  font-family: inherit;
  color: #32364e;
  border-radius: 50%;
  height: 4.8rem;
  width: 4.8rem;
  font-size: 2.4rem;
  cursor: pointer;
}

.slider__btn:hover {
  background: #f2f4fa;
}

.slider__btn--left {
  left: -5%;
  transform: translate(-50%, -50%);
}

.slider__btn--right {
  right: -5%;
  transform: translate(50%, -50%);
}

.section-data {
  transition: all 0.3s ease-in;
}

.hidden {
  transform: skewY(-3deg);
  transform: translateY(4.8rem);
  opacity: 0;
}

/* **************************** */
/* BELOW 1360px (Small desktop/laptop) */
/* **************************** */
@media (max-width: 85em) {
  .slider {
    width: 90%;
  }

  .slider__btn--left {
    left: 2%;
  }

  .slider__btn--right {
    right: 2%;
  }

  .slide-img {
    width: 95%;
  }

  .slider__btn {
    height: 3.6rem;
    width: 3.6rem;
    font-size: 1.8rem;
    top: 35%;
  }
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .slide {
    height: 50rem;
  }

  .slider {
    height: 50rem;
  }

  .heading-description {
    width: 100%;
  }

  .slide-icon {
    width: 7%;
    margin-right: 1.6rem;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .slide {
    height: 40rem;
  }

  .slider {
    height: 40rem;
  }

  .slider__btn {
    height: 3rem;
    width: 3rem;
    font-size: 1.2rem;
  }

  .slide-heading {
    font-size: 2.4rem;
    margin-bottom: 0.6rem;
  }
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  .slide {
    height: 35rem;
  }

  .slider {
    height: 35rem;
  }

  .slide-icon {
    width: 10%;
    margin-right: 1rem;
    align-self: flex-start;
  }

  .slide-heading {
    font-size: 2.2rem;
    margin-bottom: 0.4rem;
  }

  .slider__btn--left {
    left: 0%;
  }

  .slider__btn--right {
    right: 0%;
  }
}

/* **************************** */
/* BELOW 432px (mobile) */
/* **************************** */
@media (max-width: 27em) {
  .slide {
    height: 33rem;
  }

  .slider {
    height: 33rem;
  }

  .slider__btn {
    height: 2.4rem;
    width: 2.4rem;
    top: 30%;
  }
}
</style>
