<template>
  <section class="creator-why">
    <div class="container section-value-props hidden">
      <div class="section-header">
        <span class="subheading">Why INVRS</span>
        <h2 class="heading-secondary">Creators come to INVRS to:</h2>
      </div>
      <div class="grid grid--4-cols">
        <div class="value-box hidden">
          <img src="../../assets/save-time2.png" class="value-icon" />
          <h3 class="heading-tertiary">Save time</h3>
          <p class="value-description">
            Conduct your analysis, create your content, and share it - all under
            one roof.
          </p>
        </div>
        <div class="value-box hidden">
          <img src="../../assets/connect.png" class="value-icon" />
          <h3 class="heading-tertiary">Connect</h3>
          <p class="value-description">
            Interact with the DIY investors of today. Form a community around
            your work.
          </p>
        </div>
        <div class="value-box hidden">
          <img src="../../assets/grow.png" class="value-icon" />
          <h3 class="heading-tertiary">Grow</h3>
          <p class="value-description">
            Leverage a quickly growing userbase to build your brand and grow
            with us.
          </p>
        </div>
        <div class="value-box hidden">
          <img src="../../assets/monetize.png" class="value-icon" />
          <h3 class="heading-tertiary">Monetize</h3>
          <p class="value-description">
            Your work, your terms. No more relying on a middle man or algorithm
            for your income.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      valueProps: [],
      obsOptionsValueProps: {
        root: null,
        threshold: 0.4,
      },
      obsOptionsSection: {
        root: null,
        threshold: 0.15,
      },
      section: "",
    };
  },
  mounted() {
    this.valueProps = document.querySelectorAll(".value-box");
    this.section = document.querySelector(".section-value-props");
    const valuePropsObserver = new IntersectionObserver(
      this.revealValueProps,
      this.obsOptionsValueProps
    );
    const sectionObserver = new IntersectionObserver(
      this.revealSection,
      this.obsOptionsSection
    );
    valuePropsObserver.observe(this.section);
    sectionObserver.observe(this.section);
  },
  methods: {
    revealValueProps(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.valueProps.forEach((v, i) => {
            setTimeout(() => v.classList.remove("hidden"), i * 100);
          });
        }
      });
    },
    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },
  },
};
</script>

<style scoped>
.creator-why {
  padding: 9.6rem 0;
}

.section-value-props {
  transition: all 0.3s ease-in;
}

.value-description {
  font-size: 1.8rem;
  line-height: 1.6;
}

.value-icon {
  margin-bottom: 1.6rem;
  width: 6.4rem;
}

.section-header {
  display: flex;
  flex-direction: column;
}

.value-box {
  transition: all 0.3s ease-in;
}

.hidden {
  transform: translateY(4.8rem);
  opacity: 0;
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  .value-icon {
    width: 4.8rem;
  }
}
</style>
