<template>
  <section>
    <div class="container section-contact hidden">
      <div class="section-header">
        <span class="subheading">Contact Us</span>
        <h2 class="heading-secondary">Want to learn more?</h2>
        <p class="heading-description">
          Meet with our team to discover how we can help you build your
          business.
        </p>
      </div>

      <div class="creator-contact hidden-contact">
        <p class="submit-text" v-if="emailSubmitted">Thanks for reaching out! 👊 Someone from our team will connect with you as soon as possible!</p>
        <form @submit.prevent="submitCreator" v-else>
          <input
            type="email"
            placeholder="name@email.com"
            v-model="userEmail"
          />
          <input type="text" placeholder="Your first name" v-model="userName" />
          <select name="topic" id="topic" v-model="userPlatform">
            <option value="">Select main platform:</option>
            <option value="website">Website/Blog/Newsletter</option>
            <option value="twitter">Twitter</option>
            <option value="youtube">YouTube</option>
            <option value="linkedin">LinkedIn</option>
            <option value="facebook">Facebook</option>
            <option value="instagram">Instagram</option>
            <option value="tiktok">TikTok</option>
            <option value="other">Other</option>
          </select>
          <input
            type="text"
            placeholder="Social Handle/URL"
            v-model="userHandle"
          />
          <textarea
            placeholder="Your message (optional)"
            v-model="userMessage"
          />
          <button>Get in touch</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      userEmail: "",
      userName: "",
      userPlatform: "",
      userHandle: "",
      userMessage: "",
      section: "",
      portalId: "20584317",
      formGuid: "4779c22b-b062-4825-b38c-c0b0e1968625",
      obsOptionsSection: {
        root: null,
        threshold: 0.2,
      },
      emailSubmitted: false,
    };
  },

  mounted() {
    this.section = document.querySelector(".section-contact");
    const contactObserver = new IntersectionObserver(
      this.revealSection,
      this.obsOptionsSection
    );
    contactObserver.observe(this.section);
  },

  methods: {
    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },
    async submitCreator() {
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formGuid}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fields: [
              {
                objectTypeId: "0-1",
                name: "email",
                value: this.userEmail,
              },
              {
                objectTypeId: "0-1",
                name: "firstname",
                value: this.userName,
              },
              {
                objectTypeId: "0-1",
                name: "mainplatform_creator",
                value: this.userPlatform,
              },
              {
                objectTypeId: "0-1",
                name: "socialurl_creator",
                value: this.userHandle,
              },
              {
                objectTypeId: "0-1",
                name: "message",
                value: this.userMessage,
              },
            ],
          }),
        }
      );
      if (response.ok) {
        this.emailSubmitted = true;
      }
    },
  },
};
</script>

<style scoped>
section {
  padding: 9.6rem 0;
}

.section-contact {
  transition: all 0.3s ease-in;
}

.section-header {
  display: flex;
  flex-direction: column;
}
.heading-description {
  line-height: 3.5rem;
  width: 60%;
  margin-bottom: 4.8rem;
}

.creator-contact {
  border-radius: 16px;
  transition: all 0.3s ease-in;
  padding: 4.8rem;
  background-image: linear-gradient(
    to right,
    rgb(105, 59, 245, 1),
    rgb(105, 59, 245, 0.9),
    rgb(105, 59, 245, 0.8)
  );
}

.submit-text {
  color: #fff;
  text-align: center;
  line-height: 1.3;
}

form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 2.4rem;
  row-gap: 2.4rem;
}

textarea {
  grid-column: 2;
  grid-row: 1 /4;
}

input,
select {
  grid-column: 1;
}

input,
textarea,
select {
  transition: all 0.2s ease-in;
  border-radius: 8px;
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  background: #f7f8fc;
  border: 2px solid transparent;
  color: #333;
  font-family: inherit;
}

input:hover,
input:focus,
textarea:hover,
textarea:focus,
select:hover,
select:focus {
  outline: none;
  border: 2px solid #33cc99;
}

::placeholder {
  color: #a5a5a5;
}

button {
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s;
  padding: 10px 20px;
  border-radius: 0.8rem;
  background-image: linear-gradient(
    to right,
    rgb(51, 204, 153, 1),
    rgb(51, 204, 153, 0.9),
    rgb(51, 204, 153, 0.8)
  );
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  font-size: 2rem;
  margin: 0 auto;
}

button:hover {
  box-shadow: 0 0.4rem 2.4rem rgb(51, 204, 153, 0.6);
  transform: scale(1.05);
}

button:active {
  transform: scale(0.95);
}

.hidden {
  transform: translateY(4.8rem);
  opacity: 0;
}

/* **************************** */
/* BELOW 800px (tablet) */
/* **************************** */
@media (max-width: 50em) {
  .heading-description {
    width: 100%;
  }

  form {
    grid-template-columns: 1fr;
  }

  textarea {
    grid-column: 1;
    grid-row: 5;
    height: 12rem;
  }

  .creator-contact {
    width: 90%;
    margin: 0 auto;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .creator-contact {
    width: 100%;
    padding: 3.6rem;
  }
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  .creator-contact {
    padding: 2.4rem;
  }

  form {
    row-gap: 1.8rem;
  }
}
</style>
